<template>
  <div>
    <div class="row">
      <div class="col d-flex justify-content-between">
        <vs-breadcrumb
        :items="
          [
            {
              title: 'Admin Tag Store',
              url: '/'
            },
            {
              title: 'Productos',
              active: true
            }
          ]"
        ></vs-breadcrumb>
        <div>
          <vs-button icon="add" @click="open = true" type="gradient">Crear nuevo</vs-button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <vs-card>
          <vs-table stripe :data="products">
            <template slot="header">
              <h4>
                Lista de productos
              </h4>
            </template>
            <template slot="thead">
              <vs-th>
                Nombre
              </vs-th>
              <vs-th>
                Categoria
              </vs-th>
              <vs-th>
                Graffiti
              </vs-th>
              <vs-th>
                Precio
              </vs-th>
              <vs-th>
                Stock
              </vs-th>
              <vs-th>
                Acciones
              </vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr class="text-left" :key="indextr" v-for="(tr, indextr) in data" >
                <vs-td :data="data[indextr].name">
                  {{data[indextr].name}}
                </vs-td>

                <vs-td :data="data[indextr].category">
                  {{data[indextr].category.name}}
                </vs-td>

                <vs-td :data="data[indextr].graffiti">
                  {{data[indextr].graffiti ? data[indextr].graffiti.name : 'N/A'}}
                </vs-td>

                <vs-td :data="data[indextr].price">
                  {{data[indextr].price}}
                </vs-td>

                <vs-td :data="data[indextr].stock">
                  {{data[indextr].stock}}
                </vs-td>

                <vs-td :data="data[indextr].id">
                  <div class="d-flex">
                    <vs-button icon="add_a_photo" @click="openImages(data[indextr].images)" class="mr-2" color="success" type="gradient">Imagenes</vs-button>
                    <vs-button icon="create" @click="editProduct(data[indextr])" class="mr-2" color="primary" type="gradient">Editar</vs-button>
                    <vs-button icon="delete_sweep" @click="confirmDelete(data[indextr].id)" color="danger" type="gradient">Eliminar</vs-button>
                  </div>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <div>
            <vs-pagination class="mt-2" :total="total_pages" v-model="current_page"></vs-pagination>
          </div>
        </vs-card>
      </div>
    </div>

    <vs-popup classContent="popup-example" :title="edit ? 'Editar producto' : 'Registrar producto'" :active.sync="open">

      <div class="row mb-2">
        <div class="col">
          <span v-show="errors.has('category')" class="w-100 text-danger mt-2">
            {{ errors.first("category") }}
            <br>
          </span>
          <vs-select
            data-vv-as="Categoria del producto"
            v-validate="'required'"
            name="category"
            autocomplete
            class="selectExample w-100"
            label="Selecciona una categoria  *"
            v-model="product.category"
            color="success"
          >
            <vs-select-item :key="index" :value="item.id" :text="item.name" v-for="(item,index) in categories" />
          </vs-select>
        </div>
        <div class="col">
          <vs-select
            color="success"
            autocomplete
            class="selectExample w-100"
            label="Selecciona un graffiti"
            v-model="product.graffiti"
          >
            <vs-select-item :key="index" :value="item.id" :text="item.name" v-for="(item,index) in graffitis" />
          </vs-select>
        </div>
      </div>

      <div>
        <span v-show="errors.has('name')" class="w-100 text-danger mt-2">
          {{ errors.first("name") }}
        </span> <br>
        <label>Nombre del producto *</label>
        <vs-input data-vv-as="Nombre del producto" name="name" v-validate="'required'" v-model="product.name" class="inputx w-100"/>
      </div>

      <div class="mt-2">
        <label>Descripción del producto</label>
        <vs-textarea v-model="product.description" />
      </div>

      <div class="row">
        <div class="col">
          <span v-show="errors.has('price')" class="w-100 text-danger mt-2">
            {{ errors.first("price") }}
            <br>
          </span>
          <label>Precio *</label>
          <vs-input data-vv-as="Precio" name="price" v-validate="'required'" v-model="product.price" class="inputx w-100"/>
        </div>
        <div class="col">
          <label>Descuento (%)</label>
          <vs-input type="number" v-model="product.discount" class="inputx w-100"/>
        </div>
        <div class="col">
          <span v-show="errors.has('stock')" class="w-100 text-danger mt-2">
            {{ errors.first("stock") }}
            <br>
          </span>
          <label>Cantidad disponible *</label>
          <vs-input-number data-vv-as="Stock" name="stock" v-validate="'required'" v-model="product.stock"/>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <label for="">Selecciona colores disponibles</label>
          <div class="d-flex flex-wrap">
            <div class="mr-2" v-for="(item, index) in colors" :key="index">
              <vs-checkbox :color="item.hexadecimal" v-model="product.colors" :vs-value="item.hexadecimal">{{ item.value }}</vs-checkbox>
            </div>
          </div>
        </div>
        <div class="col">
          <label for="">Selecciona tallas disponibles</label>
          <div class="d-flex flex-wrap">
            <div class="mr-2" v-for="(item, index) in sizes" :key="index">
              <vs-checkbox v-model="product.sizes" :vs-value="item.value">{{ item.value }}</vs-checkbox>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <div class="button-wrapper">
            <span class="label">
              {{ files_selected }} imagenes seleccionadas <span v-if="!edit">*</span>
            </span>
            <input data-vv-as="Imagenes" name="images" v-validate="!edit ? 'required' : ''" @change="handleFile" type="file" id="upload" class="upload-box" placeholder="Subir imagenes del producto" multiple>
          </div>
          <span v-show="errors.has('images')" class="w-100 text-danger mt-2">
            {{ errors.first("images") }}
          </span>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col d-flex justify-content-between">
          <div>
            <vs-button icon="save" :disabled="loading" v-if="edit" class="mt-5" @click="updateProduct" color="primary" type="gradient">Editar</vs-button>
            <vs-button icon="save" :disabled="loading" v-else class="mt-5" @click="storeProduct" color="primary" type="gradient">Guardar</vs-button>
          </div>
        </div>
      </div>

    </vs-popup>

    <vs-popup classContent="popup-example" title="Imagenes del producto" :active.sync="open_images">
      <div class="row mb-2">
        <div v-for="(image, index) of images_product" :key="index" class="col-6">
          <vs-card actionable class="cardx">
            <div slot="media">
              <img :src="image.url">
            </div>
            <div slot="footer">
              <vs-row vs-justify="flex-end">
                <vs-button @click="deleteImage(image.id, index)" color="danger" type="gradient">Eliminar</vs-button>
              </vs-row>
            </div>
          </vs-card>
        </div>
      </div>
    </vs-popup>

  </div>
</template>

<script>
import ProductService from '@/services/product.service'
import GraffitiService from '@/services/graffitis.service'

export default {
  data() {
    return {
      products: [],
      graffitis: [],
      categories: [],
      colors: [],
      sizes: [],
      open: false,
      edit: false,
      product: {
        graffiti: '',
        category: '',
        name: '',
        description: '',
        price: 0,
        discount: 0,
        stock: 0,
        sizes: [],
        colors: [],
        images: []
      },
      current_page: 1,
      total_pages: 1,
      files_selected: 0,
      loading: false,
      open_images: false,
      images_product: []
    }
  },
  watch: {
    open(newVal) {
      if (!newVal) {
        this.edit = false
        this.product = JSON.parse(JSON.stringify(this.$options.data().product))
      }
    },
    current_page(newval) {
      this.getProducts()
    }
  },
  methods: {
    openImages(images) {
      console.log(images)
      this.images_product = images
      this.open_images = true
    },
    deleteImage(id, index) {
      this.$confirm(
        {
          message: `Seguro de eliminar la imagen?`,
          button: {
            no: 'No',
            yes: 'Si'
          },
          callback: async confirm => {
            if (confirm) {
              const { data:res } = await ProductService.deleteImageProduct({
                id: id
              })
              if (res.success) {
                this.images_product.splice(index, 1)
              } else {
                this.$swal(res.message, '', 'warning')
              }
            }
          }
        }
      )
    },
    async storeProduct() {
      this.$validator.validateAll().then(async result => {
        if (result) {
          try {
            this.loading = true
            let colors = this.product.colors.join(',')
            let sizes = this.product.sizes.join(',')

            const formData = new FormData();

            formData.append('category', this.product.category)
            formData.append('graffiti', this.product.graffiti)
            formData.append('name', this.product.name)
            formData.append('description', this.product.description)
            formData.append('price', this.product.price)
            formData.append('discount', this.product.discount)
            formData.append('stock', this.product.stock)
            formData.append('sizes', sizes)
            formData.append('colors', colors)

            for (var i = 0; i < this.product.images.length; i++ ) {
              let file = this.product.images[i]
              formData.append('images[' +i+ ']', file)
            }

            const { data:res } = await ProductService.storeProduct(formData)

            if (res.success) {
              this.open = false
              this.products.push(res.data)
              this.$vs.notify({
                title: res.message,
                text:'',
                color: 'success',
                position: 'top-right'
              })
            }

          } catch(e) {
            this.$swal('Estamos teniendo problemas', '', 'warning')
          }
          this.loading = false
        }
      })
    },
    async updateProduct() {
      this.$validator.validateAll().then(async result => {
        if (result) {
          try {
            this.loading = true
            let colors = this.product.colors.join(',')
            let sizes = this.product.sizes.join(',')

            const formData = new FormData();
            formData.append('id', this.product.id)
            formData.append('category', this.product.category)
            formData.append('graffiti', this.product.graffiti)
            formData.append('name', this.product.name)
            formData.append('description', this.product.description)
            formData.append('price', this.product.price)
            formData.append('discount', this.product.discount)
            formData.append('stock', this.product.stock)
            formData.append('sizes', sizes)
            formData.append('colors', colors)
            formData.append('_method', 'PUT')

            for (var i = 0; i < this.product.images.length; i++ ) {
              let file = this.product.images[i]
              formData.append('images[' +i+ ']', file)
            }

            const { data:res } = await ProductService.updateProduct(formData)

            if (res.success) {
              this.open = false
              const index = this.products.findIndex(i => i.id == res.data.id)
              this.products[index] = res.data
              this.open = false
              this.products = JSON.parse(JSON.stringify(this.products))
              this.$vs.notify({
                title: res.message,
                text:'',
                color: 'success',
                position: 'top-right'
              })
            }

          } catch(e) {
            this.$swal('Estamos teniendo problemas', '', 'warning')
          }
          this.loading = false
        }
      })
    },
    confirmDelete(id) {
      this.$confirm(
        {
          message: `Seguro de eliminar el producto?`,
          button: {
            no: 'No',
            yes: 'Si'
          },
          callback: async confirm => {
            if (confirm) {
              const { data:res } = await ProductService.deleteProduct({
                id: id
              })
              if (res.success) {
                const index = this.products.findIndex(i => i.id == res.data.id)
                this.products.splice(index, 1)
              } else {
                this.$swal(res.message, '', 'warning')
              }
            }
          }
        }
      )
    },
    async getGraffitis() {
      const {data:res} = await GraffitiService.getAllGraffitis()
      this.graffitis = res.data
    },
    async getAttributes() {
      const {data:res} = await ProductService.getAttributes()
      res.data.forEach(i => {
        if (i.type == 'color') {
          this.colors.push(i)
        }
        if (i.type == 'size') {
          this.sizes.push(i)
        }
      })
    },
    async getCategories() {
      const { data:res } = await ProductService.getCategories()
      this.categories = res.data
    },
    async getProducts() {
      this.$vs.loading({
        type: 'point'
      })
      const {data:res} = await ProductService.getProducts(this.current_page)
      this.products = res.data.data
      this.total_pages = res.data.last_page
      this.$vs.loading.close()
    },
    editProduct(product) {
      let item = JSON.parse(JSON.stringify(product))
      this.product.id = item.id
      this.product.category = item.category.id
      this.product.graffiti = item.graffiti ? item.graffiti.id : null
      this.product.name = item.name
      this.product.description = item.description
      this.product.price = item.price
      this.product.discount = item.discount
      this.product.stock = item.stock
      this.product.sizes = item.sizes_data
      this.product.colors = item.colors_data
      this.open = true
      this.edit = true
    },
    handleFile(event) {
      this.files_selected = 0
      event.target.files.forEach(i => {
        this.files_selected++
        this.product.images.push(i)
      })
    }
  },
  created() {
    this.getProducts()
    this.getGraffitis()
    this.getCategories()
    this.getAttributes()
  }
}
</script>

